import _webidl2jsWrapper from "./webidl2js-wrapper";
import _urlStateMachine from "./lib/url-state-machine";
import _percentEncoding from "./lib/percent-encoding";
var exports = {};
const {
  URL,
  URLSearchParams
} = _webidl2jsWrapper;
const urlStateMachine = _urlStateMachine;
const percentEncoding = _percentEncoding;
const sharedGlobalObject = {
  Array,
  Object,
  Promise,
  String,
  TypeError
};
URL.install(sharedGlobalObject, ["Window"]);
URLSearchParams.install(sharedGlobalObject, ["Window"]);
exports.URL = sharedGlobalObject.URL;
exports.URLSearchParams = sharedGlobalObject.URLSearchParams;
exports.parseURL = urlStateMachine.parseURL;
exports.basicURLParse = urlStateMachine.basicURLParse;
exports.serializeURL = urlStateMachine.serializeURL;
exports.serializeHost = urlStateMachine.serializeHost;
exports.serializeInteger = urlStateMachine.serializeInteger;
exports.serializeURLOrigin = urlStateMachine.serializeURLOrigin;
exports.setTheUsername = urlStateMachine.setTheUsername;
exports.setThePassword = urlStateMachine.setThePassword;
exports.cannotHaveAUsernamePasswordPort = urlStateMachine.cannotHaveAUsernamePasswordPort;
exports.percentDecodeString = percentEncoding.percentDecodeString;
exports.percentDecodeBytes = percentEncoding.percentDecodeBytes;
export default exports;
export const parseURL = exports.parseURL,
      basicURLParse = exports.basicURLParse,
      serializeURL = exports.serializeURL,
      serializeHost = exports.serializeHost,
      serializeInteger = exports.serializeInteger,
      serializeURLOrigin = exports.serializeURLOrigin,
      setTheUsername = exports.setTheUsername,
      setThePassword = exports.setThePassword,
      cannotHaveAUsernamePasswordPort = exports.cannotHaveAUsernamePasswordPort,
      percentDecodeString = exports.percentDecodeString,
      percentDecodeBytes = exports.percentDecodeBytes;
const _URL = exports.URL,
      _URLSearchParams = exports.URLSearchParams;
export { _URL as URL, _URLSearchParams as URLSearchParams };